<template>
  <div class="h-full bg-gray-50">
    <NuxtPage />
  </div>
</template>

<script setup>
useHead({
  bodyAttrs: {
    class: "h-full bg-gray-50",
  },
  htmlAttrs: {
    class: "h-full bg-gray-50",
  },
});
</script>

<style>
:root {
  --primary: #ff6600;
  --secondary: #ff3800;
}
</style>
